<template>
  <div class="notice">
    <pre v-if="serviceConfig.notice" v-html="serviceConfig.notice" />
    <div v-else class="none">暂无内容</div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState({
    serviceConfig: "serviceConfig",
  }),
  created() {
    if (!this.$store.state.live.chat_client) {
      this.$router.replace("/video/chat");
    }
  },
};
</script>

<style lang="scss" scoped>
.notice {
  padding: 20px;
  color: $font-color-primary;
  line-height: 22px;
}
.none {
  display: flex;
  justify-content: center;
}
</style>
